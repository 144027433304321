$(function() {
  const debounce = (fn, duration) => {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(fn, duration || 350);
    };
  };

  let numberEdited = false;

  const updateForm = (validNumber, editedForm, actualNumber) => {
    const emailField = $('#email-field');
    const submitButton = $('#email-field button.btn');
    const quickDisplay = (['theme3', 'theme4', 'theme6', 'theme9']).includes(emailField.data("theme"));
    if(!emailField.length) return;

    if ($('.container-with-phone-info').length > 0) {
      if (validNumber) {
        $(input).blur();
        submitButton.prop('disabled', false);
      } else {
        submitButton.prop('disabled', true);
      }
    }

    if (validNumber && !editedForm) {
      $('#phone-placeholder').addClass('d-none');
      $('#phone-edit').removeClass('d-none');
    }

    if (actualNumber.length <= 4) {
      numberEdited = true;
      submitButton.prop('disabled', true);
    }
    if (numberEdited) {
      if (actualNumber.length > 4) {
        numberEdited = false;
        submitButton.prop('disabled', false);
      }
    }

    if (validNumber || quickDisplay && actualNumber.length > 4 || editedForm) {
      emailField.removeClass('d-none');
      $('#phone-placeholder').addClass('d-none');
      $('#tracking').addClass('border-radius-class');

      // when in quick display and the number is valid or we are editing we dont need the rounded border
      if (quickDisplay && validNumber || quickDisplay && editedForm) {
        $('#tracking').removeClass('border-radius-class');
      }

      //this if statement is for theme8 where the form is used on other pages than home without email input
      if ($('.container-with-phone-info').length == 0) {
        submitButton.prop('disabled', false);
      }

      if (validNumber) {
        emailField.find('input[type="email"]').focus();
      }

      $(window).resize();
      $('.landing-wrapper').parent().addClass('edited-home');
      $('body').addClass('is-edited');
    }
  };

  $(window).on('resize', function () {
    const validateButton = $('#track-form #phone-placeholder');
    const editButton = $('#track-form #phone-edit');
    let right = validateButton.is(":visible") ? validateButton.outerWidth() : editButton.outerWidth();
    if (right >= $('#track-form').outerWidth()) right = 40;
    $('#track-form .intl-input-group .btn-clear-input').css('right', `${right}px`);
  });

  const input = document.querySelector('#track-form .use-intl');

  const iti = window.intlTelInputGlobals.getInstance(input);

  const clearPhoneBtn = $('.intl-input-group .btn-clear-input');

  const updateDialCode = () => $('input[name="dialCode"]').val(iti.getSelectedCountryData().dialCode);

  let editedForm = false;

  input.addEventListener("countrychange", function () {
    updateDialCode();
    $(input).keyup();
  });

  iti.promise.then(() => {
    if (typeof iti.getSelectedCountryData().dialCode === 'undefined') {
      iti.setCountry('fr');
    }
    updateDialCode();
    $(input).keyup(debounce(() => {
      var isValid = iti.isValidNumber();
      if ($('.container-with-phone-info').length > 0) {
        $(input).toggleClass('is-invalid', !isValid);
      }
      $(input).toggleClass('is-valid', isValid);
      $('.intl-input-group-container').toggleClass('is-valid', isValid);
      clearPhoneBtn.toggleClass('d-block', !isValid);
      $('#phone-empty-msg').addClass('d-none');

      updateForm(isValid, editedForm, iti.getNumber());

      if (!isValid) {
        if ($('.container-with-phone-info').length > 0) {
          $('#phone-error-msg').removeClass('d-none');
        }
      } else {
        $('#phone-error-msg').addClass('d-none');
        iti.setNumber(iti.getNumber());
      }
    }, 200));

    if ($(input).val() !== '') $(input).trigger('keyup');

    $('#phone-edit').on('click', e => {
      if(!$('#email-field').length) return;
      e.preventDefault();
      $(input).val('').focus();
      $('#email-field button.btn').prop('disabled', true);

      $(input).removeClass('is-valid');
      $(input).addClass('is-editing');

      editedForm = true;
    });

    $('#phone-placeholder').on('click',() => {
      $(input).focus();
      if ($(input).val() === '') {
        $('#phone-error-msg').addClass('d-none');
        $('#phone-empty-msg').removeClass('d-none');
        $(input).addClass('is-invalid');
      } else {
        $('#phone-empty-msg').addClass('d-none');
      }
    });
  });

  if ($(window).width() < 578) {
    const hash = location.hash.replace('#', '');
    if (hash === 'tracking') {
      $(window).scrollTop(0);
      // IOS?
      $(input).focus();
      // Android?
      window.setTimeout(function () {
        $(input).focus();
      }, 150);
    }
  }

  $(window).on('resize scroll', function () {
    if ($(window).width() < 578) {
      if (!$('#phone-placeholder').isInViewport() && $(input).val() == '' && !editedForm) {
        $('#cookie-banner').addClass('move-up');
        $('#mobile-track-btn').addClass('d-block');
      } else {
        $('#cookie-banner').removeClass('move-up');
        $('#mobile-track-btn').removeClass('d-block');
      }
    }
  });

  $(window).resize();

  $('#track-form').on('click', '.btn-clear-input', e => {
    $(e.target).parent().find('input[type="tel"], input[type="email"]').val('').focus();
  });
});
